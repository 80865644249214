import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import abc from "./thank.png";
import "./App.css";

function ThankYou() {
  const [queryParams, setQueryParams] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
        // Fire Google Ads conversion event
        window.gtag?.("event", "conversion", {
          send_to: "AW-773249637/yUY1CPCiuJIZEOW02_AC",
        });

    const getQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const params = {
        moid: searchParams.get("moid"),
        pid: searchParams.get("pid"),
        status: searchParams.get("status"),
        currency: searchParams.get("currency"),
        amount: searchParams.get("amount")
      };

      // Set the query parameters in the state
      setQueryParams(params);

      // If any of the required parameters are missing, redirect to the homepage
      if (!params.moid || !params.pid || !params.status || !params.currency || !params.amount) {
        setTimeout(() => {
          navigate("/");
        }, 7000); // 7 second delay
        
      }
    };

    getQueryParams();
    

  }, [navigate]);
  return (
    <>
      <div className="container-fluid qwert" style={{ color: "white" }}>
        <div className="row w-100">
          <section className="w-100 main-section">
            <div className="main-page-con m-auto">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="thank-you-div">
                  <img src={abc} alt="" width={100} height={100} />
                  <div className="thank-you-card w-100 mt-2">
                    <p>
                      Thank you for taking the 1st right step towards your dream
                      home.
                    </p>
                    <p>
                      <ul>
                        <li>Merchant Order Id: {queryParams.moid}</li>
                        <li>Payment Id: {queryParams.pid}</li>
                        <li>Status: {queryParams.status}</li>
                        {/* <li>Currency: {queryParams.currency}</li> */}
                        <li>
                          Amount: {queryParams.currency} 
                          {queryParams.amount}
                        </li>
                      </ul>
                    </p>
                    {/* <p style={{color:'red'}}>Note:Due to Diwali Holidays your AI Floor Plan will be delivered to you by 5th November 2024 to 6th November 2024</p> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>{" "}
    </>
  );
}

export default ThankYou;
